/*
    Content:
    1. Import fonts, frameworks, helpers
    2. Import JS styles
    3. Import objects
    4. Import components
    5. Import utilities
    6. Import IE styles
*/
/*
    1. Import fonts, frameworks, helpers
 */
@import "generic/fonts";
@import "vendor/normalize";
@import "vendor/bourbon/bourbon";
@import "generic/variables";
@import "vendor/neat/neat";
@import "generic/mixins";
@import "generic/default";
/*
    2. Import JS styles
 */
@import "vendor/slick";
@import "vendor/slick-theme";
/*
    3. Import objects
 */
@import "generic/objects";

/*
    4. Import components
 */
.c-nav {
	background-color: #363636;
	color: #dadada;
	font-size: 13px;
	@include size(100% 100px);
	z-index: 1100;
	position: relative;

	&:after {
		content: '';
		display: block;
		@include size(100% 70px);
		background-color: #444;
		@include position(absolute, 100px null null 0);
		line-height: 60px;
		z-index: -1;
	}

	@include media($min1024) {
		height: 60px;
		@include position(fixed, 0 null null 0);

		&:after {
			display: none;
		}
	}

	&--fixed {
		@include position(fixed, -100px null null 0);
		width: 100%;
		z-index: 1000;
	}
}

.is-scrolled .c-nav--fixed {
	z-index: 1200;
}

.c-nav__logo {
	width: 260px;
	display: block;
	float: left;
	margin-top: 35px;

	@include media($min1024) {
		width: 174px;
		margin-top: 20px;
	}
}

.c-nav__logo-text {
	float: right;
	margin: 20px 0 0;
	font-size: 22px;
	text-align: right;

	@include media($min1024) {
		float: left;
		margin: 12px 0 0 35px;
		font-size: 14px;
		text-align: left;
	}
}

.c-nav__address {
	float: right;
	color: #dadada;
	border-bottom: 1px dotted #dadada;
	font-size: 18px;
	margin: 39px 0 0 0;

	&:hover {
		border-bottom: none;
	}

	@include media($min1024) {
		font-size: 14px;
		margin: 20px 16px 0 0;
	}
}

.c-nav__phone {
	font-size: 24px;
	color: #FFF;
	font-weight: 500;
	margin-top: 56px;
	float: left;
	@include transition(all 200ms ease);

	@include media($min1024) {
		font-size: 18px;
		float: right;
		margin-top: 16px;
	}
}

.c-hero {
	height: 500px;
	background-position: top center;
	background-size: cover;
	position: relative;
	margin-top: 70px;

	@include media($min1024) {
		margin-top: 0;
	}
}

.c-hero__content {
	@include position(absolute, 50% null null 0);
	width: 100%;
	text-align: center;
	@include transform(translateY(-50%));
	padding-bottom: 42px;

	&:after {
		content: '';
		display: block;
		@include size(30px 1px);
		background-color: #FFF;
		@include position(absolute, null null 22px 50%);
		margin-left: -15px;
	}
}

.c-hero__title {
	margin: 0 0 16px;
	font-size: 56px;
	line-height: 1.2;
	text-transform: uppercase;
	color: #FFF;
	font-weight: 500;

	@include media($min1024) {
		font-size: 36px;
		line-height: 1.4;
		margin-bottom: 0;
	}
}

.c-hero__description {
	font-weight: 300;
	color: #FFF;
	line-height: 1.6;
	font-size: 25px;
	margin: 0;
}

.c-diagnostics {
	padding: 75px 0;
}

.c-diagnostics__wrapper {
	@include box-shadow(0px 3px 27px 0px rgba(2, 3, 3, 0.13));
	border: 1px solid #ebebeb;
	@include clearfix();
	position: relative;
	@include border-top-radius(12px);
	@include border-bottom-radius(12px);
}

.c-diagnostics__content {
	float: left;
	width: 100%;
	height: 283px;
	@include border-left-radius(12px);
	padding-left: 280px;
	padding-top: 48px;
	line-height: 1.2;

	@include media($min1024) {
		width: 65%;
		padding-top: 35px;
	}
}

.c-diagnostics__form {
	float: right;
	width: 100%;
	text-align: center;
	font-weight: 300;
	font-size: 28px;
	line-height: 1.2;
	background-color: #fafafa;
	padding: 30px;
	@include border-bottom-radius(12px);

	&:after {
		content: '!';
		text-align: center;
		@include size(48px 48px);
		display: block;
		@include border-top-radius(100%);
		@include border-bottom-radius(100%);
		line-height: 48px;
		color: #FFF;
		font-size: 25px;
		font-weight: bold;
		@include position(absolute, -24px -24px null null);
	}

	@include media($min1024) {
		height: 283px;
		width: 35%;
		position: relative;
		font-size: 21px;
		padding: 34px 30px 0;
		@include border-right-radius(12px);
		@include border-left-radius(0px);
	}
}

.c-diagnostics__man {
	@include position(absolute, -40px null null -33px);
	display: block;

	@include media($min1024) {
		bottom: 0;
		top: auto;
	}
}

.c-diagnostics__title {
	font-weight: 300;
	font-size: 35px;
	margin-bottom: 32px;
}

.c-diagnostics__phone {
	font-weight: 400;
	font-size: 26px;
	color: #979797;
	padding-left: 30px;
	display: inline-block;
	@include bg('../images/icon-phone-green.png', 20px, no-repeat, left center);

	@include media($min1024) {
		padding-left: 40px;
		font-size: 30px;
	}
}

.c-diagnostics__form__title {
	margin-bottom: 22px;
}

.c-services {
	text-align: center;
}

.c-services__title {
	margin: 16px 0 15px;
}

.c-services__description {
	font-size: 24px;
	font-weight: 300;
	margin: 0 0 36px;
}

.c-services__engine-type {
	max-width: 470px;
	margin: 0 auto 48px;
	@include clearfix();
}

.c-services__engine-item:first-child {
	float: left;
}

.c-services__engine-item:last-child {
	float: right;
}

.c-services__engine-item {
	&:hover {
		.c-services__engine-img {
			border: 1px solid #ebebeb;
		}
		.c-services__engine-media {
			&:after {
				content: '';
				display: block;
				@include position(absolute, 0 null null 0);
				background-position: center center;
				background-size: 42px;
				@include size(100%);
				background-repeat: no-repeat;
				background-image: url('../images/icon-drop-gray.png');
			}
		}
	}
}

.c-services__engine-item--active {
	.c-services__engine-media {
		&:after {
			content: '';
			display: block;
			@include position(absolute, 0 null null 0);
			background-position: center center;
			background-size: 42px;
			@include size(100%);
			background-repeat: no-repeat;
		}
	}
}

.c-services__engine-media {
	position: relative;
	margin-bottom: 15px;
}

.c-services__engine-img {
	display: block;
	border: 1px solid #ebebeb;
	@include border-top-radius(16px);
	@include border-bottom-radius(16px);
}

.c-services__engine-title {
	font-weight: 300;
	font-size: 21px;
	color: #1a1a1a;
}

.c-services__pricing {
	@include clearfix();
	text-align: left;
	margin-bottom: 80px;
}

.c-services__pricing-left {
	float: left;
	width: 49%;
}

.c-services__pricing-right {
	float: right;
	width: 49%;
}

.c-services__pricing-item {
	@include clearfix();
	margin-bottom: 6px;
	font-weight: 300;
	font-size: 18px;
	@include border-top-radius(25px);
	@include border-bottom-radius(25px);

	&:nth-child(odd) {
		.c-services__pricing-title {
			background-color: #f9f9f9;
		}
	}

	a {
		color: #1a1a1a;
	}
}

.c-services__pricing-price {
	float: left;
	font-weight: 700;
	color: #FFF;
	height: 50px;
	line-height: 48px;
	width: 30%;
	text-align: center;
	font-size: 14px;
	@include border-right-radius(25px);
}

.c-services__pricing-title {
	height: 50px;
	float: left;
	line-height: 48px;
	width: 70%;
	@include border-left-radius(25px);
	border: 1px solid #ebebeb;
	border-right: none;
	padding-left: 26px;

	&:before {
		content: '•';
		margin-right: 5px;
	}
}

.c-promo {
	padding: 75px 0 52px;
	background: #222222;
	background: -moz-linear-gradient(top, #222222 0%, #303030 100%);
	background: -webkit-linear-gradient(top, #222222 0%, #303030 100%);
	background: linear-gradient(to bottom, #222222 0%, #303030 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#222222', endColorstr='#303030', GradientType=0);
}

.c-promo__title {
	color: #FFF;
	margin: 0 0 38px;
}

.c-promo__list {
	padding-left: 45%;
	color: #FFF;
	font-size: 18px;
	font-weight: 300;
	@include bg('../images/engine.png', 373px, no-repeat, left center);
}

.c-promo-item {
	line-height: 36px;
	position: relative;
	margin-bottom: 8px;

	&:before {
		content: '';
		@include size(36px);
		display: inline-block;
		vertical-align: middle;
		margin-right: 18px;
		position: relative;
		top: -2px;
		@include border-top-radius(100%);
		@include border-bottom-radius(100%);
		@include bg('../images/icon-check-white.png', 19px, no-repeat, center);
	}
}

.c-malfunctions {
	padding: 85px 0;
}

.c-malfunctions__title {
	margin: 0 0 35px;
}

.c-malfunctions-left {
	float: left;
	width: 49%;
}

.c-malfunctions-right {
	float: right;
	width: 49%;
}

.c-malfunctions-item:nth-child(odd) {
	color: #FFF;
}

.c-malfunctions-item {
	@include border-top-radius(25px);
	@include border-bottom-radius(25px);
	height: 50px;
	line-height: 48px;
	background-color: #f9f9f9;
	color: #363636;
	font-size: 18px;
	padding: 0 30px;
	border: 1px solid #ebebeb;
	margin-bottom: 6px;
}

.c-call-to-action {
	color: #363636;
	padding: 10px 0 60px;

	@include media($min1024) {
		padding: 90px 0;
	}
}

.c-call-to-action__title-large {
	font-size: 64px;
	text-transform: uppercase;
	font-weight: 900;
	line-height: 1;

	@include media($min1024) {
		font-size: 70px;
	}
}

.c-call-to-action__title-small {
	font-size: 31px;
	text-transform: uppercase;
	font-weight: 900;
	line-height: 1;

	@include media($min1024) {
		font-size: 34px;
	}
}

.c-call-to-action__content {
	float: left;
	width: 100%;

	@include media($min1024) {
		width: 70%;
	}
}

.c-call-to-action__list {
	float: left;
	width: 100%;
	font-size: 24px;
	font-weight: 900;
	text-transform: uppercase;
	line-height: 1.4;
	padding-top: 40px;

	@include media($min1024) {
		width: 30%;
		font-size: 15px;
		line-height: 1.2;
		padding-top: 7px;
	}
}

.c-call-to-action__list-item {
	padding: 2px 0 2px 42px;
	margin-bottom: 20px;
	background-size: 30px;
	background-repeat: no-repeat;
	background-position: left center;

	@include media($min1024) {
		margin-bottom: 13px;
	}
}

.c-call-to-action__title {
	margin-bottom: 30px;

	@include media($min1024) {
		margin-bottom: 66px;
	}
}

.c-call-to-action__sale {
	color: #FFF;
	font-weight: 900;
	text-transform: uppercase;
	@include size(94% 154px);
	padding-left: 150px;
	padding-top: 35px;
	@include bg('../images/icon-like.png', 80px, no-repeat, left 32px center);
	position: relative;

	&:after {
		content: '';
		@include size(0 0);
		border-style: solid;
		border-width: 77px 0 77px 30px;
		@include position(absolute, 0 -30px null null);
	}

	@include media($min1024) {
		width: 88%;
		padding-top: 34px;
		background-size: 90px;
	}
}

.c-call-to-action__sale-small {
	font-size: 34px;
	line-height: 1.2;

	@include media($min1024) {
		font-size: 40px;
		line-height: 1;
	}
}

.c-call-to-action__sale-large {
	font-size: 44px;
	line-height: 1.2;

	@include media($min1024) {
		font-size: 52px;
		line-height: 1;
	}
}

.c-contacts-info {
	padding: 30px 0 75px;

	@include media($min1024) {
		padding-bottom: 106px;
	}
}

.c-contacts-info__wrapper {
	@extend .c-diagnostics__wrapper;
	padding: 35px 0 0 232px;
	height: 285px;
	text-align: center;

	@include media($min1024) {
		padding-top: 45px;
		padding-left: 272px;
	}

	&:after {
		content: '!';
		text-align: center;
		@include size(48px 48px);
		display: block;
		@include border-top-radius(100%);
		@include border-bottom-radius(100%);
		line-height: 48px;
		color: #FFF;
		font-size: 25px;
		font-weight: bold;
		@include position(absolute, -24px -24px null null);
	}
}

.c-contacts-info__man {
	@include position(absolute, null null 0 0px);
	display: block;

	@include media($min1024) {
		left: 50px;
	}
}

.c-contacts-info__title {
	text-transform: uppercase;
	line-height: 1.2;

	@include media($mobile) {
		font-size: 28px;
		br {
			display: none;
		}
	}
}

.c-contacts-info__text {
	font-size: 21px;
	font-weight: 300;

	@include media($mobile) {
		font-size: 24px;
		padding: 10px 20px 0;
		line-height: 1.2;
	}
}

.c-contacts-info__phone {
	@extend .c-diagnostics__phone;
	margin-top: 18px;

	@include media($min1024) {
		margin-top: 32px;
	}
}

.c-certificates {
	padding: 78px 0 88px;

	padding-bottom: 132px; // remove it
	@include bg('../uploads/banner-2.jpg', cover, no-repeat, top center);
}

.c-certificates__title {
	color: #FFF;
	margin-bottom: 36px;
}

.c-certificates__list {
	text-align: center;
	font-size: 0;
}

.c-certificates__item {
	display: inline-block;
	margin: 0 14px;
}

.c-certificates__img {
	display: block;
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
	max-width: 212px;
}

.c-reviews {
	padding: 85px 0;
	position: relative;
}

.c-reviews__title {
	margin-bottom: 25px;
}

.c-reviews__item {
	padding: 20px;
}

.c-reviews__item-content {
	padding: 12px;
	position: relative;
	@include size(100% 285px);
	@include border-top-radius(12px);
	@include border-bottom-radius(12px);
	border: 1px solid #ebebeb;
	@include box-shadow(0px 3px 27px 0px rgba(2, 3, 3, 0.13));
}

.c-reviews__avatar {
	border: 1px solid #ebebeb;
	@include border-top-radius(12px);
	@include border-bottom-radius(12px);
}

.c-reviews__media {
	float: left;
	width: 42%;
}

.c-reviews__content {
	float: left;
	width: 54%;
	text-align: center;
	padding-top: 18px;
}

.c-reviews__author-name {
	font-size: 24px;
	text-transform: uppercase;
}

.c-reviews__author-auto {
	text-transform: uppercase;
	font-size: 16px;
	margin-bottom: 12px;
}

.c-reviews__author-text {
	font-size: 21px;
	font-weight: 300;
	text-align: justify;
}

.c-reviews__list {
	margin-bottom: 50px;
}

.c-reviews__add {
	@include position(absolute, 180px null null 50%);
	@include transform(translateX(-50%));
	z-index: 500;
	background-color: #FFF;
	max-width: 920px;
	width: 100%;
	@include border-top-radius(12px);
	@include border-bottom-radius(12px);

	.c-reviews__content {
		padding-top: 10px;
	}

	.c-reviews__author-text {
		margin-bottom: 24px;
	}
}

.c-reviews__label-avatar {
	overflow: hidden;
	display: block;
	position: relative;

	&.error {
		img {
			border-color: red;
		}
	}

	img {
		display: block;
		@include border-top-radius(12px);
		@include border-bottom-radius(12px);
		border: 1px solid #ebebeb;
		@include size(333px 259px);
	}

	span {
		color: #adacac;
		font-size: 18px;
		font-weight: 300;
		@include position(absolute, 10px null null 120px);
	}
}

.c-reviews__input-name {
	float: left;
	width: 48%;
}

.c-reviews__input-phone {
	float: right;
	width: 48%;
}

.c-reviews__textarea {
	@extend .o-input;
}

.c-reviews__input-name {
	@include bg('../images/icon-user-gray.png', 18px, no-repeat, left 16px center);
}
.c-reviews__textarea {
	@include bg('../images/icon-textarea-gray.png', 18px, no-repeat, left 16px top 10px);
	padding-top: 10px;
	padding-bottom: 10px;
	height: 116px;
	margin-bottom: 0;
}

.c-gallery__slider {
	.slick-prev {
		left: 50px;
	}
	.slick-next {
		right: 50px;
	}
}

.c-map {
	position: relative;
	min-height: 426px;
}

.c-map__title {
	text-align: center;
	@include position(absolute, -44px null null 50%);
	display: inline-block;
	font-size: 35px;
	text-transform: uppercase;
	z-index: 1000;
	background-color: #FFF;
	padding: 20px 50px;
	@include border-top-radius(12px);
	@include border-bottom-radius(12px);
	@include transform(translateX(-50%));
	min-width: 520px;
}

.c-footer {
	background-color: #ebebeb;
	padding: 40px 0 60px;
	color: #4f4f4f;
	font-size: 22px;
	font-weight: 300;

	@include media($min1024) {
		font-size: 15px;
	}
}

.c-footer__top {
	@include clearfix();
	margin-bottom: 32px;
}

.c-footer__logo {
	width: 260px;
	display: block;
	float: left;

	@include media($min1024) {
		width: 174px;
	}
}

.c-footer__top-right {
	float: right;
	text-align: right;
}

.c-footer__phone {
	font-size: 28px;
	font-weight: 400;

	@include media($min1024) {
		font-size: 23px;
	}
}

.c-footer__middle {
	@include clearfix();
	margin-bottom: 22px;
}

.c-footer__middle-left {
	float: left;

	@include media($mobile) {
		margin-bottom: 20px;
	}
}

.c-footer__middle-right {

	@include media($min1024) {
		float: right;
		text-align: right;
	}
}

.c-footer__bottom {
	color: #9a9a9a;
	text-align: justify;
}

.c-modal-overlay {
	visibility: hidden;
	opacity: 0;
	z-index: 4000;
	background-color: rgba(0, 0, 0, 0.45);
	@include size(0);
	@include position(fixed, 0 null null 0);
	@include transition(opacity 200ms ease);

	&.is-opened {
		opacity: 1;
		visibility: visible;
		@include size(100%);
	}
}

.c-modal {
	visibility: hidden;
	opacity: 0;
	@include position(fixed, 50% null null 50%);
	@include transform(translate(-50%, -50%));
	width: 300px;
	padding: 30px 30px 40px;
	text-align: center;
	@include bg('../uploads/modal-bg.jpg', cover, no-repeat, top center);
	@include border-top-radius(12px);
	@include border-bottom-radius(12px);
	z-index: 5000;
	border: 2px solid #FFF;
	@include box-shadow(1px 2px 8px 0px rgba(0, 0, 0, 0.54));
	@include transition(opacity 200ms ease);

	@include media($min1024) {
		width: 354px;

		&--map {
			width: 450px;
			background-color: #FFF;
			background-image: none;
		}
	}

	&.is-opened {
		opacity: 1;
		visibility: visible;
	}
}

.c-modal__title {
	@extend .c-reviews__author-name;
	margin-bottom: 20px;
}

.c-modal__text {
	font-size: 18px;
	margin-bottom: 40px;
}

.c-modal__info {
	position: relative;
	margin-bottom: 20px;

	&:before {
		content: '';
		display: block;
		background-color: #898989;
		@include size(30px 1px);
		margin-left: -15px;
		z-index: 5;
		@include position(absolute, -10px null null 50%);
	}
}

.c-modal__close {
	@include position(absolute, 12px 12px null null);
	display: block;
	@include size(12px);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.c-modal__map {
	margin-left: -30px;
	margin-right: -30px;
	margin-bottom: 20px;
}

.c-reviews__close {
	@extend .c-modal__close;
	top: 18px;
	right: 18px;
}

/*
    5. Import utilities
 */
@import "generic/utilities";
/*
    6. Import IE styles
 */
@import "generic/ie";
