.o-container {
	@include clearfix();
	padding: 0 30px;

	@include media($min1024) {
		padding: 0;
		@include outer-container();
	}
}

.o-input {
	background-color: #FAFAFA;
	@include size(100% 80px);
	border: 1px solid #e1e1e1;
	font-size: 28px;
	font-weight: 300;
	color: #adacac;
	padding: 0 12px 0 46px;
	@include border-top-radius(6px);
	@include border-bottom-radius(6px);
	@include bg('../images/icon-phone-gray.png', 14px, no-repeat, left 18px center);
	margin-bottom: 20px;

	@include media($min1024) {
		height: 60px;
		font-size: 18px;
	}
}

.o-submit {
	border: none;
	text-transform: uppercase;
	font-size: 22px;
	font-weight: bold;
	color: #FFF;
	display: block;
	@include size(100% 80px);
	@include border-top-radius(6px);
	@include border-bottom-radius(6px);

	&:hover {
		background-size: 400%;
	}

	@include media($min1024) {
		@include size(100% 60px);
		font-size: 15px;
	}
}

.o-title {
	text-align: center;
	font-weight: 500;
	font-size: 35px;
	text-transform: uppercase;
	margin: 0;
}

.o-button {
	display: inline-block;
	text-align: center;
	color: #FFF;
	text-transform: uppercase;
	height: 60px;
	line-height: 60px;
	font-size: 15px;
	font-weight: 900;
	padding: 0 80px;
	width: auto;
	font-family: 'Roboto', Arial, sans-serif;
	@include border-top-radius(6px);
	@include border-bottom-radius(6px);

	&:hover {
		background-size: 400%;
	}
}