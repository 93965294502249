@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-loader-path: "../images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	}
	@else {
		@return url($slick-loader-path + $url);
	}
}

/* Slider */

.slick-list {
	.slick-loading & {
		background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	@include size(50px);
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	z-index: 500;
	background-position: center;
	background-size: 10px;
	background-repeat: no-repeat;
	@include border-top-radius(100%);
	@include border-bottom-radius(100%);
	@include transition(background-color 200ms ease);
}

.slick-prev {
	left: 0;
	background-image: url('../images/icon-arrow-left.png');

	@include media($min1280) {
		left: -100px;
	}
}

.slick-next {
	right: 0;
	background-image: url('../images/icon-arrow-right.png');

	@include media($min1280) {
		right: -100px;
	}
}

/* Dots */

.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	bottom: -25px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		height: 20px;
		width: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			border: 0;
			background: transparent;
			display: block;
			height: 20px;
			width: 20px;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			color: transparent;
			padding: 5px;
			cursor: pointer;
			&:hover, &:focus {
				outline: none;
			}
		}
	}
}
