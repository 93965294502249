/*
    Content:
    1. Fields & Form buttons
    2. Links
    3. Lists
    4. Paragraphs
    5. Html, Body
 */

/*
    1. Fields & Form buttons
 */
input,
textarea {
	outline: none;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	@include placeholder {
		color: #adacac;
		opacity: 1;
	}
	@include border-top-radius(0);
	@include border-bottom-radius(0);

	&.error {
		border-color: red;
	}
}

textarea { resize: none; }
button { outline: none; cursor: pointer; }

input[type="checkbox"] {
	appearance: checkbox;
	-moz-appearance: checkbox;
	-webkit-appearance: checkbox;
}

/*
    2. Links
 */
a {
	color: $base-link--color;
	outline: none;
	text-decoration: none;
	&:hover { text-decoration: none; }
}

/*
    3. Lists
 */
ul {
	padding: 0;
	margin: 0;
	li { list-style: none; }
}

/*
    4. Paragraphs
 */
p {
	word-break: break-word;
	overflow-wrap: break-word;
	white-space: normal;
}

/*
    5. Html, Body
 */
body {
	@include position();
	overflow-x: hidden;
	//min-width: 1024px;
	-webkit-text-size-adjust: none;
	min-width: 640px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: $base-line-height;
	font-size: $base-font-size;
	color: $base-font--color;
	font-family: $base-font;

	@include media($min1024) {
		padding-top: 60px;
	}
}